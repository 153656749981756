/*
 *
 * Application Main
 *
 */
!(function(APP) {
    'use strict';


    var breakpoint = 768;

    $.extend(true, APP, {
        breakpoint: breakpoint,
        init: {
            form: function() {
                var tel = $('.phone');

                if (tel) { tel.inputmask(); }
            }
            // init functions
            // form: function() {
            //     let form = $('.formc');
            //     let phoneDigits = 11;

            //     if (!form) {
            //         return;
            //     }
            //     var tx = $('.formc input[type="tel"]');
            //     var email = $('.formc input[type="email"]');
            //     var tel = $('.formc input[type="tel"]');
            //     let txts = $('.formc input[type="text"]');
            //     let txtarea = $('.formc textarea');
            //     let checkboxs = $('.formc input[type="checkbox"]');

            //     function checkIputs(selector) {
            //         let txtsubmit = true;

            //         $(selector).each((index)=>{
            //             let inp = selector.eq(index);

            //             if (inp.val().length === 0) {
            //                 inp.addClass('is-invalid');
            //                 inp.removeClass('is-valid');
            //                 txtsubmit = false;
            //             } else {
            //                 inp.removeClass('is-invalid');
            //                 inp.addClass('is-valid');
            //             }
            //         });
            //         return txtsubmit;
            //     }
            //     function checkmail(email) {
            //         var mailsubmit = true;
            //         var expr = /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

            //         email.each(index=>{
            //             if (expr.test(email.eq(index).val())) {
            //                 email.eq(index).removeClass('is-invalid');
            //                 email.eq(index).addClass('is-valid');
            //             } else {
            //                 email.eq(index).addClass('is-invalid');
            //                 email.eq(index).removeClass('is-valid');
            //                 mailsubmit = false;
            //             }
            //         });

            //         return mailsubmit;
            //     }
            //     function checkphone() {
            //         var checkboxsubmit = true;

            //         tx.each(index=>{
            //             if (tx.eq(index).val()) {
            //                 if (tx.eq(index).val().match(/[0-9]/g).length === phoneDigits) {
            //                     tx.eq(index).removeClass('is-invalid');
            //                     tx.eq(index).addClass('is-valid');
            //                 }
            //             } else {
            //                 tx.eq(index).addClass('is-invalid');
            //                 tx.eq(index).removeClass('is-valid');
            //                 checkboxsubmit = false;
            //             }
            //         });
            //         return checkboxsubmit;
            //     }
            //     function checkCheckBox() {
            //         let submitchk = true;

            //         checkboxs.each(index=>{
            //             let checked = checkboxs[index].checked;

            //             if (!checked) {
            //                 checkboxs.eq(index).removeClass('is-valid');
            //                 checkboxs.eq(index).addClass('is-invalid');
            //                 submitchk = false;
            //             } else {
            //                 checkboxs.eq(index).addClass('is-valid');
            //                 checkboxs.eq(index).removeClass('is-invalid');
            //             }
            //         });
            //         return submitchk;
            //     }

            //     if (tel) { tel.inputmask(); } // mask phone number
            //     // watch on submit
            //     form.submit(function(event) {

            //         if (txtarea) {

            //             if (!checkIputs(txtarea)) {
            //                 event.preventDefault();
            //             }
            //         }
            //         if (txts) {
            //             if (!checkIputs(txts)) {
            //                 event.preventDefault();
            //             }
            //         }
            //         if (tel) {
            //             if (!checkphone()) {
            //                 event.preventDefault();
            //             }
            //         }
            //         if (checkboxs) {
            //             if (!checkCheckBox()) {
            //                 event.preventDefault();
            //             }
            //         }
            //         if (email) {
            //             if (!checkmail(email)) {
            //                 event.preventDefault();
            //             }
            //         }
            //     });
            // }
        },

        INIT: function(options) {
            // APP init

            options = options || {};

            var fn;

            for (var i in this.init) {
                if ( Object.prototype.hasOwnProperty.call(this.init, i) && i.charAt(0) !== '_' && typeof(fn = this.init[i]) === 'function' ) {
                    fn.call(this, options && options[i] || options);
                }
            }

            return this;
        }
    });

})(window.APP = window.APP || {});

$(function() {
    'use strict';

    APP.browser = (function() {

        var is = APP.Helper.is,
            val, tmp,
            userAgent = APP.sanitizeXss(navigator.userAgent);

        var browser = {
            mobile: !!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(userAgent),
            ios: !!/iPhone|iPad|iPod/i.test(userAgent),
            ie: (tmp = userAgent.toLowerCase().match(/MSIE ([^;]+)|rv:(11)\.[0-9]+/i)) ? parseInt(tmp[1] || tmp[2], 10) : false,
            edge: (tmp = userAgent.indexOf('Edge/')) > 0 ? parseInt(userAgent.substring(tmp + 5, userAgent.indexOf('.', tmp)), 10) : false,
            bp: function() {
                return $(window).width() < APP.breakpoint;
            }
        };

        var $el = $('html'); // document.documentElement

        for (var k in browser ) {
            if ( Object.prototype.hasOwnProperty.call(browser, k) ) {
                val = browser[k];

                if ( val && !is.function(val) ) {
                    $el.addClass(k);
                    if ( !is.boolean(val) ) {
                        $el.addClass(k + val);
                    }
                }
            }
        }

        APP.browser = browser;

        return browser;
    }());

    APP.scriptPath = APP.Helper.getScriptPath(['app.js', 'app.min.js', 'main.js']);

    APP.Helper.loadScript(APP.scriptPath + 'config.js', {
        success: function() {
            APP.INIT(CONFIG);
        },
        failed: function() {
            APP.INIT();
        }
    });
});
